var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":"","grow":"","pa-1":""}},[_c('CustomerBySectorStackedChart',{attrs:{"officeSectorID":[
            '50',
            '49',
            '54',
            '56',
            '55',
            '52',
            '62',
            '53',
            '51',
            '64',
            '65',
            '59',
            '57',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]',"customerCategory":1,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'เปอร์เซ็นต์ลูกค้ากลุ่ม องค์กรปกครองส่วนท้องถิ่น[1]',"customerCategory":1,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้าสาธารณสุข กลุ่มโรงพยาบาลของรัฐ[9]',"customerCategory":9,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'เปอร์เซ็นต์ลูกค้าสาธารณสุข กลุ่มโรงพยาบาลของรัฐ[9]',"customerCategory":9,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้า รพ.สต.สังกัด(กรมส่งเสริมการปกครองท้องถิ่น)[15]',"customerCategory":15,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'เปอร์เซ็นต์ลูกค้า รพ.สต.สังกัด(กรมส่งเสริมการปกครองท้องถิ่น)[15]',"customerCategory":15,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษา (สพฐ.)[2]',"customerCategory":2,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerPercentBySectorStackedChart',{attrs:{"chartTitle":'เปอร์เซ็นต์ลูกค้ากลุ่มสถานศึกษา (สพฐ.)[2]',"customerCategory":2,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม ส่วนงานราชการ[11]',"customerCategory":11,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษาสกร.(เดิม กศน.)[3]',"customerCategory":3,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษาเอกชน[4]',"customerCategory":4,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษา (กรมส่งเสริมการปกครองท้องถิ่น)[5]',"customerCategory":5,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษาอาชีวศึกษา[6]',"customerCategory":6,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษาสังกัดสำนักพุทธ[7]',"customerCategory":7,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่มสถานศึกษา (อุดมศึกษา)[8]',"customerCategory":8,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม ส่วนงานสาธารณสุข (รพ. เอกชน)[10]',"customerCategory":10,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้าเอกชน(ที่ไม่ใช่กลุ่มสาธารณสุขและสถานศึกษา)[14]',"customerCategory":14,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'ลูกค้ารายใหญ่ที่เป็นบุคคล [13]',"customerCategory":13,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg6":"","xl6":"","grow":"","pa-1":""}},[_c('CustomerHasCRMDataOrSpecialBillDataPerCustomerBySectorStackedChart',{attrs:{"chartTitle":'จำนวนลูกค้ากลุ่ม อื่นๆ[12]',"customerCategory":12,"officeSectorID":[
            '49',
            '50',
            '51',
            '52',
            '53',
            '54',
            '55',
            '56',
            '57',
            '59',
            '62',
            '64',
            '65',
          ]}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }